import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createPricingTemplate,
  editPricingTemplate,
  editPricingTemplateStatus,
  retrievePricingTemplate
} from '../../../../src/app/api'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * usePricingTemplate
 */
export const usePricingTemplate = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const {
    data,
    isInitialLoading,
    isError: isErrorRetrieveTemplate
  } = useQuery(['TEMPLATE', location.state?.id], retrievePricingTemplate, {
    enabled: location.state?.id !== undefined
  })

  // Create Template mutation
  const {
    mutate: createTemplateMutation,
    data: createTemplateData,
    isLoading: isLoadingCreateTemplate,
    isError: isErrorCreateTemplate
  } = useMutation(createPricingTemplate, {
    onSuccess(data, variables) {
      if (variables.targetStatus !== 'DRAFT')
        editTemplateStatusMutation({
          status: variables.targetStatus,
          templateId: data.data.id,
          userGuid: variables.userGuid
        })
      else {
        navigate('/')
      }
    }
  })

  // Edit Template mutation
  const {
    mutate: editTemplateMutation,
    data: editTemplateData,
    isLoading: isLoadingEditTemplate,
    isError: isErrorEditTemplate
  } = useMutation(editPricingTemplate, {
    onSuccess: (data, variables) => {
      if (variables.targetStatus !== 'DRAFT') {
        editTemplateStatusMutation({
          status: variables.targetStatus,
          templateId: data?.data.id
        })
      } else {
        navigate('/')
      }
    }
  })

  // Edit Template Status mutation
  const {
    mutate: editTemplateStatusMutation,
    data: editTemplateStatusData,
    isLoading: isLoadingEditStatusTemplate,
    isError: isErrorEditStatusTemplate
  } = useMutation(editPricingTemplateStatus, { onSuccess: () => navigate('/') })

  const isLoading =
    isInitialLoading ||
    isLoadingCreateTemplate ||
    isLoadingEditTemplate ||
    isLoadingEditStatusTemplate

  const isError =
    isErrorRetrieveTemplate ||
    isErrorCreateTemplate ||
    isErrorEditTemplate ||
    isErrorEditStatusTemplate

  return {
    data,
    createTemplateData,
    editTemplateData,
    editTemplateStatusData,
    createTemplateMutation,
    editTemplateMutation,
    editTemplateStatusMutation,
    isLoading,
    isError
  }
}
