import { Presets } from 'date-token-format'

interface ToastPresetsType {
  DATE_SHORTER: Intl.DateTimeFormatOptions
  DATE_SHORT_WITHOUT_YEAR: Intl.DateTimeFormatOptions
  DATE_MEDIUM_WITHOUT_YEAR: Intl.DateTimeFormatOptions
  DATE_LONG: Intl.DateTimeFormatOptions
  DATE_LONG_WITHOUT_DAYS: Intl.DateTimeFormatOptions
  DATE_LONG_WITHOUT_YEAR: Intl.DateTimeFormatOptions
  TIME_SHORTER: Intl.DateTimeFormatOptions
  TIME_MEDIUM: Intl.DateTimeFormatOptions
  TIME_LONG: Intl.DateTimeFormatOptions
  TIME_FULL: Intl.DateTimeFormatOptions
  DATETIME_SHORTER: Intl.DateTimeFormatOptions
  DATETIME_SHORT: Intl.DateTimeFormatOptions
  DATETIME_SHORT_24: Intl.DateTimeFormatOptions
  DATETIME_MEDIUM: Intl.DateTimeFormatOptions
  DATETIME_LONG: Intl.DateTimeFormatOptions
  DATETIME_FULL: Intl.DateTimeFormatOptions
  TIMEZONE_SHORT: Intl.DateTimeFormatOptions
  TIMEZONE_LONG: Intl.DateTimeFormatOptions
}

export const ToastPresets: ToastPresetsType = {
  DATE_SHORTER: {
    year: '2-digit',
    day: 'numeric',
    month: 'numeric'
  },

  DATE_SHORT_WITHOUT_YEAR: {
    day: 'numeric',
    month: 'numeric'
  },

  DATE_MEDIUM_WITHOUT_YEAR: {
    day: 'numeric',
    month: 'short'
  },

  DATE_LONG: {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  },

  DATE_LONG_WITHOUT_DAYS: {
    month: 'long',
    year: 'numeric'
  },

  DATE_LONG_WITHOUT_YEAR: {
    month: 'long',
    day: 'numeric'
  },

  TIME_SHORTER: {
    hour: 'numeric',
    hour12: true
  },

  TIME_MEDIUM: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  },

  TIME_LONG: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  },

  TIME_FULL: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'longOffset'
  },

  DATETIME_SHORTER: {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    hour12: true
  },

  DATETIME_SHORT: {
    ...Presets.DATETIME_SHORT,
    hour12: true
  },

  DATETIME_SHORT_24: {
    ...Presets.DATETIME_SHORT,
    hour12: false
  },

  DATETIME_MEDIUM: {
    ...Presets.DATETIME_MEDIUM,
    hour12: true
  },

  DATETIME_LONG: {
    ...Presets.DATETIME_LONG_WITH_SECONDS,
    timeZoneName: 'short'
  },

  DATETIME_FULL: {
    ...Presets.DATETIME_LONG_WITH_SECONDS,
    timeZoneName: 'longOffset'
  },

  TIMEZONE_SHORT: {
    timeZoneName: 'short'
  },

  TIMEZONE_LONG: {
    timeZoneName: 'long'
  }
}
