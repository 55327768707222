/* eslint-disable no-undef */
import { AppProps } from 'single-spa'
const NAME = 'banquet-theme-manage' as const

export const state = {
  count: 0,
  isMounted: false
}

export type CssThemeLifecyclesOpts<P> = {
  getCssAssets: (props: P) => string[]
  timeout?: number
  shouldUnmount?: boolean
  createLink?: (url: string) => HTMLLinkElement
}

type LinkElements = {
  [url: string]: HTMLLinkElement
}

type ElementsToUnmount = [HTMLLinkElement, string]

/**
 * cssThemeLifecycles
 * @param opts: CssThemeLifecyclesOpts
 * @returns CSSLifecycles
 */
export function cssThemeLifecycles<Props extends AppProps>(
  opts: CssThemeLifecyclesOpts<Props>
) {
  if (!opts || typeof opts !== 'object') {
    throw Error(`${NAME}: opts must be an object`)
  }

  const options = {
    ...{
      timeout: 5000,
      shouldUnmount: true,
      createLink: (url: string) => {
        const linkEl = document.createElement('link')
        linkEl.href = url
        linkEl.rel = 'stylesheet'
        return linkEl
      }
    },
    ...opts
  }

  const linkElements: LinkElements = {}
  let linkElementsToUnmount: ElementsToUnmount[] = []

  /**
   * bootstrap - Preload CSS assets for the theme
   * @param props: AppProps
   * @param, opts.theme - ThemeManager
   * @returns Promise
   */
  function bootstrap(props: Props) {
    const cssAssets = options.getCssAssets(props)

    return Promise.all(
      cssAssets.map((cssAsset) => {
        return new Promise<void>((resolve) => {
          const preloadEl = document.querySelector(
            `link[rel="preload"][as="style"][href="${cssAsset}"]`
          )

          if (!preloadEl) {
            const linkEl = document.createElement('link')
            linkEl.rel = 'preload'
            linkEl.setAttribute('as', 'style')
            linkEl.href = cssAsset
            document.head.appendChild(linkEl)
          }
          resolve()
        })
      })
    )
  }

  /**
   * mount - Mount the theme
   * @param props: AppProps
   * @param, opts.theme - ThemeManager
   * @returns Promise
   */
  function mount(props: Props) {
    state.count++
    if (state.count > 1 && state.isMounted === true) {
      return Promise.resolve()
    }
    const cssAssets = options.getCssAssets(props)
    return Promise.all(
      cssAssets.map((cssAsset) => {
        return new Promise<void>((resolve, reject) => {
          const existingLinkEl = document.querySelector(
            `link[rel="stylesheet"][href="${cssAsset}"]`
          )

          if (existingLinkEl) {
            linkElements[cssAsset] = existingLinkEl as HTMLLinkElement
            resolve()
          } else {
            const timeout = setTimeout(() => {
              reject(
                `${NAME}: While mounting '${props.name}', loading CSS from URL ${linkEl.href} timed out after ${options.timeout}ms`
              )
            }, options.timeout)
            const linkEl = options.createLink(cssAsset)
            linkEl.addEventListener('load', () => {
              clearTimeout(timeout)
              resolve()
            })
            linkEl.addEventListener('error', () => {
              clearTimeout(timeout)
              reject(
                Error(
                  `${NAME}: While mounting '${props.name}', loading CSS from URL ${linkEl.href} failed.`
                )
              )
            })
            linkElements[cssAsset] = linkEl
            document.head.appendChild(linkEl)

            linkElementsToUnmount.push([linkEl, cssAsset])
          }
        })
      })
    )
  }

  /**
   * unmount - Unmount the theme
   * @returns Promise
   */
  function unmount(_props: Props) {
    const elements = linkElementsToUnmount
    let shouldSkipUnmount = false
    if (state.count > 1) {
      shouldSkipUnmount = true
    }
    state.count--
    if (shouldSkipUnmount) {
      return Promise.resolve()
    }
    // reset this array immediately so that only one mounted instance tries to unmount
    // the link elements at a time
    linkElementsToUnmount = []

    return Promise.all(
      elements.map(([linkEl, url]) =>
        Promise.resolve().then(() => {
          delete linkElements[url]
          if (linkEl.parentNode) {
            linkEl.parentNode.removeChild(linkEl)
          }
        })
      )
    ).finally(() => {
      state.isMounted = false
    })
  }

  return {
    bootstrap,
    mount,
    unmount
  }
}
