import * as React from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { ReadonlyText } from '@toasttab/buffet-pui-readonly'
import { Badge } from '@toasttab/buffet-pui-badge'
import { Status, getBadgeColour } from '@local/pricing-template-table'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import {
  DatePickerField,
  NumberInputField,
  SelectField,
  SelectMultipleField,
  TextInputField
} from '@toasttab/buffet-pui-forms'
import { Formik } from 'formik'
import { TemplateStatusModal } from '../TemplateStatusModal'
import { TemplateFormButtonGroup } from '../TemplateFormButtonGroup'
import { CardProcessorSection } from '../CardProcessorSection'
import { useBanquetProps } from 'banquet-runtime-modules'
import { TemplateData, TemplateForm } from './types'
import { usePricingTemplate } from '../usePricingTemplate'
import { validationSchema } from './validationSchema'
import { buildCreateRequest, buildEditRequest } from './utilities'

export interface PricingTemplateBaseCardProps {
  testId?: string | number
  isNewCard: boolean
  templateId?: string
  templateData: TemplateData
}

export const trimChannelName = (brand: string) => {
  return brand.replaceAll('_', ' ')
}

/**
 * PricingTemplateBaseCard component
 */
export const PricingTemplateBaseCard = (
  props: PricingTemplateBaseCardProps
) => {
  const { createTemplateMutation, editTemplateMutation } = usePricingTemplate()
  const isActive = props.templateData?.status === 'APPROVED'
  const isInReview = props.templateData?.status === 'IN_REVIEW'

  const { auth } = useBanquetProps()
  const userGuid = auth?.userInfo.guid

  const [modalOpen, setModalOpen] = React.useState(false)

  const closeModalHandler = () => {
    setModalOpen(false)
  }

  const submitHandler = (formValues: TemplateForm) => {
    props.isNewCard
      ? buildCreateRequest(createTemplateMutation, formValues, userGuid!)
      : buildEditRequest(
          editTemplateMutation,
          formValues,
          userGuid!,
          props.templateId!
        )
  }

  return (
    <Formik
      initialValues={{
        description: props.templateData.description,
        salesChannels: props.templateData.salesChannels,
        channel: props.templateData.channel,
        type: props.templateData.type,
        gpv: props.templateData.gpv,
        qualifiers: props.templateData.qualifiers,
        effectiveStartDate: props.isNewCard
          ? props.templateData.effectiveDate
          : new Date(props.templateData.effectiveDate),
        monthlyCCFee: props.templateData.monthlyCCFee,
        targetStatus: props.templateData?.status ?? 'DRAFT',
        cardProcessorSettingsAMEX: props.templateData.cardProcessorSettingsAmex,
        cardProcessorSettingsVI_MC_DI:
          props.templateData.cardProcessorSettingsVI_MC_DI,
        countryCodes: props.templateData.countryCodes[0],
        model: props.templateData.model
      }}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
    >
      {(formik) => {
        return (
          <CardContainer data-testid={props.testId}>
            <section className='mb-10 pb-10 border-b-2'>
              <div className='mb-10'>
                <div className='grid grid-cols-2 gap-x-4 mb-4'>
                  <TextInputField
                    label='Description'
                    required
                    size='sm'
                    helperIconButton={
                      <InfoTooltip>
                        Descriptive name of this pricing template
                      </InfoTooltip>
                    }
                    containerClassName='w-2/3'
                    placeholder='Enter a description'
                    name={'description'}
                    disabled={isActive || isInReview}
                  />
                  <ReadonlyText
                    label='Status'
                    helperIconButton={
                      <IconButton
                        onClick={() => {
                          setModalOpen(true)
                        }}
                        icon={<InfoIcon accessibility='decorative' />}
                      />
                    }
                    value={
                      <Badge
                        size='lg'
                        color={getBadgeColour(
                          props.templateData?.status ?? 'DRAFT'
                        )}
                      >
                        {props.isNewCard ? 'DRAFT' : props.templateData?.status}
                      </Badge>
                    }
                  />
                </div>
                <div className='grid grid-cols-2 gap-x-4 mb-4'>
                  <SelectField
                    label='Channel'
                    containerClassName='w-1/3'
                    options={['TOAST_PAYMENTS']}
                    name='channel'
                    helperIconButton={<InfoTooltip>Channel.....</InfoTooltip>}
                    required
                    size='sm'
                    disabled={true}
                  />
                  <SelectField
                    label='Type'
                    containerClassName='w-1/3'
                    options={['STANDARD', 'CUSTOM']}
                    name='type'
                    helperIconButton={<InfoTooltip>Type.....</InfoTooltip>}
                    size='sm'
                    required
                    disabled={true}
                  />
                </div>
                <div className='grid grid-cols-2 gap-x-16 w-2/5'>
                  <DatePickerField
                    label='Effective start date'
                    required
                    disabled={isActive || isInReview}
                    name={'effectiveStartDate'}
                  />
                </div>
              </div>
              <CardContainer noElevation>
                <p className='type-large font-semibold pb-4'>
                  Merchant Criteria
                </p>
                <div className='grid grid-cols-2 gap-x-4 mb-4'>
                  <SelectMultipleField
                    label='Qualifiers'
                    containerClassName='w-2/3'
                    options={['SURCHARGING', 'AMEX_DIRECT']}
                    name='qualifiers'
                    helperIconButton={
                      <InfoTooltip>
                        List of attributes a merchant must have to qualify for
                        this pricing template.
                      </InfoTooltip>
                    }
                    size='sm'
                    disabled={isActive || isInReview}
                    enableSelectAll
                  />
                  <SelectMultipleField
                    label='GPV'
                    required
                    helperIconButton={
                      <InfoTooltip>
                        Multiple selection of the GPV ranges that this template
                        can apply to
                      </InfoTooltip>
                    }
                    containerClassName='w-2/3'
                    options={[
                      { label: '<$500K', value: 'SMALL' },
                      { label: '$500K-$1M', value: 'MEDIUM' },
                      { label: '$1M-$3M', value: 'LARGE' },
                      { label: '$3M+', value: 'EXTRA_LARGE' }
                    ]}
                    name='gpv'
                    disabled={isActive || isInReview}
                    size='sm'
                    enableSelectAll
                  />
                </div>
                <div className='grid grid-cols-2 gap-x-4 mb-4'>
                  <SelectMultipleField
                    label='Sales channel'
                    containerClassName='w-2/3'
                    options={['SALESFORCE']}
                    name='salesChannels'
                    helperIconButton={
                      <InfoTooltip>
                        Lists the sales channels where this template should be
                        available.
                      </InfoTooltip>
                    }
                    required
                    disabled={true}
                    size='sm'
                  />
                  <SelectField
                    containerClassName='w-2/3'
                    name='countryCodes'
                    disabled={true}
                    defaultValue={'US'}
                    options={['US', 'Ireland', 'United Kingdom', 'Canada']}
                    label='Location'
                    required
                    // disabled={isActive || isInReview}
                  />
                </div>
              </CardContainer>
            </section>
            <section className='mb-10 pb-10 border-b-2'>
              <SelectField
                label='Model'
                containerClassName='w-1/6'
                options={['FLAT_RATE', 'INTERCHANGE_PLUS']}
                name='model'
                helperIconButton={
                  <InfoTooltip>
                    Pricing model, currently either flat rate or IC+
                  </InfoTooltip>
                }
                size='sm'
                required
                disabled={isActive || isInReview}
              />
              <CardProcessorSection
                disabled={isActive || isInReview}
                model={formik.values.model}
                isCombinedRate={
                  formik.values.cardProcessorSettingsVI_MC_DI.combinedRates
                }
                isSplitRate={
                  formik.values.cardProcessorSettingsVI_MC_DI.isSplitRate
                }
                brand={'VI_MC_DI'}
                channelClassifications={['CARD_PRESENT', 'CARD_NOT_PRESENT']}
              />
              <CardProcessorSection
                disabled={isActive || isInReview}
                brand={'AMEX'}
                brandDisplayName={
                  formik.values.qualifiers.includes('AMEX_DIRECT')
                    ? 'AMEX DIRECT'
                    : 'AMEX OptBlue'
                }
                model={formik.values.model}
                isCombinedRate={
                  formik.values.cardProcessorSettingsAMEX.combinedRates
                }
                channelClassifications={['CARD_PRESENT', 'CARD_NOT_PRESENT']}
              />
            </section>
            <section className='mb-10 pb-10 border-b-2'>
              <NumberInputField
                prefix='$'
                disabled={isActive || isInReview}
                containerClassName='w-1/3'
                prefixVariant='bgGray'
                label='Monthly Service Fee'
                name={'monthlyCCFee'}
              />
            </section>
            <TemplateFormButtonGroup
              status={props.templateData?.status ?? 'DRAFT'}
              buttonClickHandler={(targetStatus: Status): void => {
                formik.setFieldValue('targetStatus', targetStatus)
                formik.handleSubmit()
              }}
            />
            <TemplateStatusModal
              isModalOpen={modalOpen}
              closeModalHandler={closeModalHandler}
            />
          </CardContainer>
        )
      }}
    </Formik>
  )
}
