export const Locales = [
  'en-AU',
  'en-CA',
  'en-IE',
  'en-NZ',
  'en-GB',
  'en-US',
  'es-US'
] as const

export type Locale = (typeof Locales)[number]
