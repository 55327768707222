import axios from 'axios'

const query = axios.create({
  baseURL: '/api/service/pricing-templates/v1/'
})

/**
 * GET Requests
 */

export const retrievePricingTemplates = () => {
  return query.get(`/templates`)
}

export const retrievePricingTemplate = (props: {
  queryKey: (string | undefined)[]
}) => {
  return query.get(`/templates/${props.queryKey[1]}`)
}

/**
 * POST/PUT Requests
 */

type CardTransactionFees = {
  brand: string
  cardPresence: { type: string }
  funding: { type: string }
  fee: {
    percent?: number
    fixedAmount?: number
  }
  model: string
}

export type TemplateRequestBody = {
  type: string
  description: string
  channel: string
  paymentMethod: string
  currency: string
  criteria: {
    gpvRanges: string[]
    countryCodes: string[]
    qualifiers: string[]
    salesChannels: string[]
  }
  effectiveDate: string
  throughDate?: string
  otherFees: any[]
  monthlyCCFee?: number
  cardTransactionFees: CardTransactionFees[]
}

export const createPricingTemplate = (props: {
  params: TemplateRequestBody
  targetStatus: string
  userGuid?: string
}) => {
  return query.post('/templates', props.params, {
    headers: { 'Toast-User-Guid': props.userGuid }
  })
}

export const editPricingTemplate = (props: {
  params: TemplateRequestBody
  templateId: string
  targetStatus: string
  userGuid?: string
}) => {
  return query.put(`/templates/${props.templateId}`, props.params, {
    headers: { 'Toast-User-Guid': props.userGuid }
  })
}

export const editPricingTemplateStatus = (props: {
  status: string
  templateId: string
  userGuid?: string
}) => {
  return query.patch(
    `/templates/${props.templateId}`,
    { status: props.status },
    {
      headers: { 'Toast-User-Guid': props.userGuid }
    }
  )
}
