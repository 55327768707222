import * as React from 'react'
import cx from 'classnames'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import { Tooltip } from '../Tooltip'

import type { IconButtonProps } from '@toasttab/buffet-pui-buttons'
import type { TooltipProps } from '../Tooltip'

export interface InfoTooltipProps
  extends Pick<TooltipProps, 'mobileTimeout' | 'testId' | 'placement'> {
  ariaLabel?: string
  /** The message content of the tooltip. Should be a string */
  children: React.ReactNode
  /** Class name to pass to the IconButton */
  className?: string
  /** Icon to pass to the IconButton */
  icon?: IconButtonProps['icon']
  /** Size to pass to the IconButton */
  size?: IconButtonProps['size']
  /** Flag to pass to the IconButton */
  cropToIcon?: IconButtonProps['cropToIcon']
  /** Flag to pass to the IconButton */
  disabled?: IconButtonProps['disabled']
}

export const InfoTooltip = ({
  placement,
  mobileTimeout,
  testId = 'info-tooltip',
  children,
  className,
  ariaLabel = 'More info',
  icon = <InfoIcon accessibility='decorative' />,
  size = 'auto',
  cropToIcon,
  disabled
}: InfoTooltipProps) => {
  return (
    <Tooltip
      placement={placement}
      mobileTimeout={mobileTimeout}
      testId={testId}
    >
      <Tooltip.Trigger.IconButton
        icon={icon}
        cropToIcon={cropToIcon}
        aria-label={ariaLabel}
        iconColor='secondary'
        data-testid={`${testId}-open-button`}
        // type-caption forces this icon button not to be too tall in some stretchy layouts...
        className={cx(className, 'type-caption')}
        size={size}
        disabled={disabled}
      />
      {/* Do not to show tooltips on disabled icons */}
      <Tooltip.Content disabled={disabled}>{children}</Tooltip.Content>
    </Tooltip>
  )
}
