/* eslint-disable @toasttab/buffet/date-formats */
/* Default locale date strings, based on en-US locale */

import { Presets } from 'date-token-format'
import { ToastPresets } from './toastPresets'

export const Formats = {
  date: {
    /** Example (en-US): 4/29/53 */
    shorter: ToastPresets.DATE_SHORTER,
    /** Example (en-US): 4/29/1453 */
    short: Presets.DATE_SHORT,
    /** Example (en-US): 4/29 */
    short_without_year: ToastPresets.DATE_SHORT_WITHOUT_YEAR,
    /** Example (en-US): Apr 29 */
    medium_without_year: ToastPresets.DATE_MEDIUM_WITHOUT_YEAR,
    /** Example (en-US): Apr 29, 1453 */
    medium: Presets.DATE_MEDIUM,
    /** Example (en-US): April 29, 1453 */
    long: ToastPresets.DATE_LONG,
    /** Example (en-US): April 1453 */
    long_without_days: ToastPresets.DATE_LONG_WITHOUT_DAYS,
    /** Example (en-US): April 29 */
    long_without_year: ToastPresets.DATE_LONG_WITHOUT_YEAR,
    /** Example (en-US): Friday, April 29th, 1453 */
    full: Presets.DATE_FULL
  },
  time: {
    /** Example (en-US): 2 AM */
    shorter: ToastPresets.TIME_SHORTER,
    /** Example (en-US): 2:00 AM */
    short: Presets.TIME,
    /** Example (en-US): 13:00 */
    short_24: Presets.TIME_24,
    /** Example (en-US): 12:00:00 AM */
    medium: ToastPresets.TIME_MEDIUM,
    /** Example (en-US): 12:00:00 AM GMT+2 */
    long: ToastPresets.TIME_LONG,
    /** Example (en-US): 12:00:00 AM GMT+02:00 */
    full: ToastPresets.TIME_FULL
  },
  dateTime: {
    /** Example (en-US): 4/9/53, 2 AM */
    shorter: ToastPresets.DATETIME_SHORTER,
    /** Example (en-US): 4/9/1453, 12:00 AM */
    short: ToastPresets.DATETIME_SHORT,
    /** Example (en-US): 4/9/1453, 13:00 */
    short_24: ToastPresets.DATETIME_SHORT_24,
    /** Example (en-US): Apr 29, 1453, 11:00 AM */
    medium: ToastPresets.DATETIME_MEDIUM,
    /** Example (en-US): Apr 29, 1453, 11:00:00 AM */
    medium_with_seconds: Presets.DATETIME_MEDIUM_WITH_SECONDS,
    /** Example (en-US): April 29th, 1453 at 12:00:00 AM GMT+2 */
    long: ToastPresets.DATETIME_LONG,
    /** Example (en-US): Friday, April 29th, 1453 at 11:00:00 AM GMT+02:00 */
    full: ToastPresets.DATETIME_FULL
  },
  timeZone: {
    /** Example: GMT or GMT-5, depending on timeZone being set and locale */
    short: ToastPresets.TIMEZONE_SHORT,
    /** Example: Greenwich Meantime */
    long: ToastPresets.TIMEZONE_LONG
  }
} as const

export const DateFormats = {
  date: {
    /** Example (en-US): 4/29/53 */
    shorter: 'date_shorter',
    /** Example (en-US): 4/29/1453 */
    short: 'date_short',
    /** Example (en-US): 4/29 */
    short_without_year: 'date_short_without_year',
    /** Example (en-US): Apr 29 */
    medium_without_year: 'date_medium_without_year',
    /** Example (en-US): Apr 29, 1453 */
    medium: 'date_medium',
    /** Example (en-US): April 29, 1453 */
    long: 'date_long',
    /** Example (en-US): April 1453 */
    long_without_days: 'date_long_without_days',
    /** Example (en-US): April 29 */
    long_without_year: 'date_long_without_year',
    /** Example (en-US): Friday, April 29th, 1453 */
    full: 'date_full'
  },
  time: {
    /** Example (en-US): 2 AM */
    shorter: 'time_shorter',
    /** Example (en-US): 2:00 AM */
    short: 'time_short',
    /** Example (en-US): 13:00 */
    short_24: 'time_short_24',
    /** Example (en-US): 12:00:00 AM */
    medium: 'time_medium',
    /** Example (en-US): 12:00:00 AM GMT+2 */
    long: 'time_long',
    /** Example (en-US): 12:00:00 AM GMT+02:00 */
    full: 'time_full'
  },
  dateTime: {
    /** Example (en-US): 4/9/53, 2 AM */
    shorter: 'dateTime_shorter',
    /** Example (en-US): 4/9/1453, 12:00 AM */
    short: 'dateTime_short',
    /** Example (en-US): 4/9/1453, 13:00 */
    short_24: 'dateTime_short_24',
    /** Example (en-US): Apr 29, 1453, 11:00 AM */
    medium: 'dateTime_medium',
    /** Example (en-US): Apr 29, 1453, 11:00:00 AM */
    medium_with_seconds: 'dateTime_medium_with_seconds',
    /** Example (en-US): April 29th, 1453 at 12:00:00 AM GMT+2 */
    long: 'dateTime_long',
    /** Example (en-US): Friday, April 29th, 1453 at 11:00:00 AM GMT+02:00 */
    full: 'dateTime_full'
  },
  timeZone: {
    /** Example: GMT or GMT-5, depending on timeZone being set and locale */
    short: 'timeZone_short',
    /** Example: Greenwich Meantime */
    long: 'timeZone_long'
  }
} as const

// Ensure any new formats added above are also set up within defaultFormats
type DefaultFormats = Record<
  | `date_${keyof typeof Formats.date}`
  | `time_${keyof typeof Formats.time}`
  | `dateTime_${keyof typeof Formats.dateTime}`
  | `timeZone_${keyof typeof Formats.timeZone}`,
  string
>

export const defaultFormats: DefaultFormats = {
  date_short_without_year: 'M/d',
  date_shorter: 'M/d/yy',
  date_short: 'M/d/yyyy',
  date_medium: 'PP',
  date_medium_without_year: 'MMM d',
  date_long: 'MMMM d, yyyy',
  date_long_without_days: 'MMMM yyyy',
  date_long_without_year: 'MMMM d',
  date_full: 'PPPP',
  time_shorter: 'h a',
  time_short: 'p',
  time_short_24: 'HH:mm',
  time_medium: 'pp',
  time_long: 'ppp',
  time_full: 'pppp',
  dateTime_shorter: 'M/d/yy, h a',
  dateTime_short: 'M/d/yyyy, h:mm a',
  dateTime_short_24: 'M/d/yyyy, HH:mm',
  dateTime_medium: 'MMM d, yyyy, h:mm a',
  dateTime_medium_with_seconds: 'PPpp',
  dateTime_long: 'PPPppp',
  dateTime_full: 'PPPPpppp',
  timeZone_short: 'zzz',
  timeZone_long: 'zzzz'
}

// Apply an optional subset of formats within locale files
export type OptionalFormats = Partial<DefaultFormats>

export const Masks: Record<string, string> = {
  short: 'mask_short',
  ymd: 'ymd'
} as const

export type Mask = {
  placeholder: string[]
  delimiter: string
  mask: string[]
  pattern: string[]
  parseFormat: {
    input: string
    output: string
  }
}

type DefaultMasks = Record<string, Mask>

export type OptionalDateMaskFormat = Partial<DefaultMasks>

export const defaultMasks: DefaultMasks = {
  mask_short: {
    placeholder: ['MM', 'DD', 'YYYY'],
    delimiter: '/',
    mask: ['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y'],
    pattern: ['##', '##', '####'],
    parseFormat: {
      input: 'MMddyyyy',
      output: 'MM/dd/yyyy'
    }
  },
  ymd: {
    placeholder: ['YYYY', 'MM', 'DD'],
    delimiter: '-',
    mask: ['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D'],
    pattern: ['####', '##', '##'],
    parseFormat: {
      input: 'yyyyMMdd',
      output: 'yyyy-MM-dd'
    }
  }
}
