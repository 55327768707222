import * as React from 'react'
import {
  FieldHelperProps,
  FieldHookConfig,
  FieldInputProps,
  FieldMetaProps,
  useField
} from 'formik'

export type UseFieldFastProps<V> = string | FieldHookConfig<V>

export function useFieldFast<V = any>(
  props: UseFieldFastProps<V>
): [FieldInputProps<V>, FieldMetaProps<V>, FieldHelperProps<V>] {
  const [field, meta, helpers] = useField(props)

  const latestRef = React.useRef<{
    helpers?: {
      setValue: FieldHelperProps<V>['setValue']
      setTouched: FieldHelperProps<V>['setTouched']
      setError: FieldHelperProps<V>['setError']
    }
    setValue?: FieldHelperProps<V>['setValue']
    setTouched?: FieldHelperProps<V>['setTouched']
    setError?: FieldHelperProps<V>['setError']
  }>({})

  // On every render save newest helpers to latestRef
  latestRef.current.setValue = helpers.setValue
  latestRef.current.setTouched = helpers.setTouched
  latestRef.current.setError = helpers.setError

  // On the first render create new function which will never change
  // but call newest helper function
  if (!latestRef.current.helpers) {
    latestRef.current.helpers = {
      setValue: (...args) => latestRef.current.setValue!(...args),
      setTouched: (...args) => latestRef.current.setTouched!(...args),
      setError: (...args) => latestRef.current.setError!(...args)
    }
  }

  return [field, meta, latestRef.current.helpers]
}
