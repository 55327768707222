import React, { forwardRef, useContext } from 'react'
import cx from 'classnames'
import { ArrowDownIcon, ArrowUpIcon } from '@toasttab/buffet-pui-icons'
import { t, loadStrings } from '../defaultStrings'

type TableVariant = 'plain' | 'striped' | 'summary-card'
type TableDensity = 'default' | 'condensed' | 'condensed-x' | 'condensed-y'
type TableCellVerticalAlignment = 'top' | 'middle'

interface TableTheme {
  /** The style variant for the table. Note that 'summary-card' is only for use in dashboard or summary cards and ignores the density prop */
  variant: TableVariant
  /** The density setting for the cells of the table */
  density: TableDensity
  /** For multi-line content is the alignment to the top or middle? */
  valign: TableCellVerticalAlignment
}

const TableThemeContext = React.createContext<TableTheme>({
  variant: 'striped',
  density: 'default',
  valign: 'top'
})

function useTableTheme() {
  const context = useContext(TableThemeContext)
  return context
}

const densityPaddings = {
  default: 'py-3 px-4',
  condensed: 'py-2 px-2',
  'condensed-x': 'py-3 px-2',
  'condensed-y': 'py-2 px-4'
}

export type CellProps = JSX.IntrinsicElements['td'] & {
  testId?: string
  className?: string
}

export const Cell = ({ children, className, testId, ...props }: CellProps) => {
  const { variant: tableVariant, valign, density } = useTableTheme()
  return (
    <td
      data-testid={testId}
      className={cx(
        'type-default font-normal',
        tableVariant === 'summary-card'
          ? 'first:pl-0 last:pr-0 py-3 px-2 leading-none'
          : densityPaddings[density],
        valign === 'top' ? 'align-top' : 'align-middle',
        className
      )}
      {...props}
    >
      {children}
    </td>
  )
}

export type HeadingCellProps = JSX.IntrinsicElements['td'] & {
  testId?: string
  className?: string
  noMinWidth?: boolean
  isSorted?: boolean
}

export const HeadingCell = ({
  children,
  className,
  testId,
  noMinWidth,
  isSorted,
  ...props
}: HeadingCellProps) => {
  const { variant: tableVariant, density } = useTableTheme()
  const { variant: rowVariant } = useRowTheme()
  return (
    <th
      data-testid={testId}
      className={cx(
        'text-left',
        tableVariant === 'summary-card' && rowVariant !== 'sub-section'
          ? 'pt-0 pb-4 px-2 first:pl-0 last:pr-0 leading-none'
          : tableVariant === 'summary-card'
          ? 'py-3 px-2 first:pl-0 last:pr-0'
          : densityPaddings[density],
        { 'min-w-table-cell': !noMinWidth },
        isSorted ||
          (rowVariant === 'sub-section' && tableVariant !== 'summary-card')
          ? 'font-semibold text-default'
          : 'font-normal text-secondary',
        className
      )}
      {...props}
    >
      {children}
    </th>
  )
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none'
}

export type SortIndicatorProps = Omit<JSX.IntrinsicElements['div'], 'ref'> & {
  direction?: SortDirection
}

export const SortIndicator = React.forwardRef(
  (
    { direction, className, ...props }: SortIndicatorProps,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    loadStrings()
    return (
      <div ref={ref} className={cx('inline-flex ml-1', className)} {...props}>
        {direction === SortDirection.DESC ? (
          <ArrowDownIcon
            className='px-0 py-0'
            aria-label={t('sorted-descending')}
          />
        ) : direction === SortDirection.ASC ? (
          <ArrowUpIcon
            className='px-0 py-0'
            aria-label={t('sorted-ascending')}
          />
        ) : (
          <ArrowUpIcon
            className='px-0 py-0 text-disabled'
            aria-label={t('not-sorted')}
          />
        )}
      </div>
    )
  }
)

export type RowProps = JSX.IntrinsicElements['tr'] & {
  testId?: string
  className?: string
  variant?: 'default' | 'sub-section'
}

const TableRowContext = React.createContext<RowProps>({
  variant: 'default'
})

function useRowTheme() {
  const context = useContext(TableRowContext)
  return context
}

export const Row = forwardRef<HTMLTableRowElement, RowProps>(
  (
    { children, className, testId, variant = 'default', ...props }: RowProps,
    ref
  ) => {
    const { variant: tableVariant } = useTableTheme()
    return (
      <TableRowContext.Provider value={{ variant }}>
        <tr
          data-testid={testId}
          className={cx(
            'border-default',
            {
              'even:bg-darken-4':
                tableVariant === 'striped' && variant !== 'sub-section',
              'border-b':
                tableVariant === 'plain' ||
                (tableVariant === 'striped' && variant === 'sub-section'),
              'border-b last:border-b-0': tableVariant === 'summary-card'
            },
            {
              'bg-gray-25 border-t-2':
                variant === 'sub-section' && tableVariant !== 'summary-card'
            },
            className
          )}
          ref={ref}
          {...props}
        >
          {children}
        </tr>
        {/* IMPORTANT: this ~~dirty trick~~ empty TR forces the correct striping order for subsections */}
        {variant === 'sub-section' && tableVariant === 'striped' && <tr></tr>}
      </TableRowContext.Provider>
    )
  }
)
Row.displayName = 'Row'

export type HeadProps = JSX.IntrinsicElements['thead'] & {
  testId?: string
  className?: string
}

export const Head = ({ children, className, testId, ...props }: HeadProps) => {
  return (
    <thead className={className} data-testid={testId} {...props}>
      {children}
    </thead>
  )
}

export type BodyProps = JSX.IntrinsicElements['tbody'] & {
  testId?: string
  className?: string
}

export const Body = forwardRef<HTMLTableSectionElement, BodyProps>(
  ({ testId, className, children, ...props }, ref) => {
    const { variant: tableVariant } = useTableTheme()
    return (
      <tbody
        data-testid={testId}
        className={cx(
          tableVariant !== 'summary-card' &&
            'border-t-2 border-b border-darken-12',
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </tbody>
    )
  }
)

export type FootProps = JSX.IntrinsicElements['tfoot'] & {
  testId?: string
  className?: string
}

export const Foot = ({ children, className, testId, ...props }: FootProps) => {
  return (
    <tfoot className={className} data-testid={testId} {...props}>
      {children}
    </tfoot>
  )
}

type OptionalTableThemeProps = {
  variant?: 'plain' | 'striped'
  density?: TableDensity
  valign?: TableCellVerticalAlignment
}

interface TableThemingPropsForSummaryCard {
  variant: 'summary-card'
  density?: 'default' | undefined
  valign?: TableCellVerticalAlignment
}

// union to prohibit using summary-card with density
type TableThemingProps =
  | TableThemingPropsForSummaryCard
  | OptionalTableThemeProps

export type TableProps = {
  /* Optional test id */
  testId?: string
  /* Additional CSS Classes */
  className?: string
} & TableThemingProps & { children?: React.ReactNode }

export const Table = forwardRef<HTMLTableElement, TableProps>(
  (
    {
      children,
      className,
      variant = 'striped',
      density = 'default',
      valign = 'top',
      testId = '',
      ...props
    }: TableProps,
    ref
  ) => {
    let safeDensity = density
    if (variant === 'summary-card' && density !== 'default') {
      console.warn(
        `This is an unsupported configuration. You cannot use a density of "${density}" with the summary-card variant of Table. Reverting to "default" density.`
      )
      safeDensity = 'default'
    }

    return (
      <TableThemeContext.Provider
        value={{ variant, density: safeDensity, valign }}
      >
        <table
          className={cx('w-full', className)}
          data-testid={testId}
          ref={ref}
          {...props}
        >
          {children}
        </table>
      </TableThemeContext.Provider>
    )
  }
)

Table.displayName = 'Table'
