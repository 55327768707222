import { Colors } from '@toasttab/buffet-pui-badge'
import { Status } from '../PricingTemplateTable'

export const gpvRanges = {
  SMALL: '<$500K',
  MEDIUM: '$500K-$1M',
  LARGE: '$1M-$3M',
  EXTRA_LARGE: '$3M+'
}

export const getGPVRangeLabel = (gpv: string) => {
  switch (gpv) {
    case 'SMALL':
      return gpvRanges.SMALL
    case 'MEDIUM':
      return gpvRanges.MEDIUM
    case 'LARGE':
      return gpvRanges.LARGE
    case 'EXTRA_LARGE':
      return gpvRanges.EXTRA_LARGE
    default:
      return null
  }
}

export const getGPVBadgeColour = (gpv: string) => {
  switch (gpv) {
    case 'SMALL':
      return Colors.neutral0
    case 'MEDIUM':
      return Colors.neutral3
    case 'LARGE':
      return Colors.neutral2
    case 'EXTRA_LARGE':
      return Colors.neutral1
    default:
      return Colors.info
  }
}

export const getBadgeColour = (status: Status) => {
  switch (status) {
    case 'DRAFT':
      return Colors.neutral0
    case 'APPROVED':
      return Colors.success
    case 'IN_REVIEW':
      return Colors.neutral1
    case 'ARCHIVED':
      return Colors.error
    default:
      return Colors.info
  }
}
