import { UseMutateFunction } from '@tanstack/react-query'
import {
  CardProcessorConfigAMEX,
  CardProcessorConfigVCMD,
  Model,
  TemplateForm
} from './types'
import { AxiosResponse } from 'axios'
import { TemplateRequestBody } from '../../../../src/app/api'

export const formatCardTransactionFees = (
  amexFees: CardProcessorConfigAMEX,
  vmcdFees: CardProcessorConfigVCMD,
  model: Model
) => {
  const array: {
    brand: string
    cardPresence: {
      type: string
    }
    funding: {
      type: string
    }
    fee: {
      percent?: number
      fixedAmount?: number
    }
    model: string
  }[] = []

  const formatRateForAPI = (rate: number) => {
    return rate / 100
  }

  if (amexFees.combinedRates && amexFees.CP_CNP) {
    array.push({
      brand: 'AMEX',
      cardPresence: {
        type: 'CARD_PRESENT_OR_NOT_PRESENT'
      },
      funding: {
        type: 'CREDIT_DEBIT_PREPAID'
      },
      fee: {
        percent: formatRateForAPI(amexFees.CP_CNP?.rate),
        fixedAmount: amexFees.CP_CNP?.fee
      },
      model: model
    })
  } else if (amexFees.CARD_PRESENT && amexFees.CARD_NOT_PRESENT) {
    array.push({
      brand: 'AMEX',
      cardPresence: { type: 'CARD_PRESENT' },
      funding: { type: 'CREDIT_DEBIT_PREPAID' },
      fee: {
        percent: formatRateForAPI(amexFees.CARD_PRESENT?.rate),
        fixedAmount: amexFees.CARD_PRESENT?.fee
      },
      model: model
    })
    array.push({
      brand: 'AMEX',
      cardPresence: { type: 'CARD_NOT_PRESENT' },
      funding: { type: 'CREDIT_DEBIT_PREPAID' },
      fee: {
        percent: formatRateForAPI(amexFees.CARD_NOT_PRESENT?.rate),
        fixedAmount: amexFees.CARD_NOT_PRESENT?.fee
      },
      model: model
    })
  }

  if (vmcdFees.combinedRates && vmcdFees.CP_CNP) {
    array.push({
      brand: 'VI_MC_DI',
      cardPresence: { type: 'CARD_PRESENT_OR_NOT_PRESENT' },
      funding: { type: 'CREDIT_DEBIT_PREPAID' },
      fee: {
        percent: formatRateForAPI(vmcdFees.CP_CNP?.rate),
        fixedAmount: vmcdFees.CP_CNP?.fee
      },
      model: model
    })
  } else if (vmcdFees.CARD_PRESENT && vmcdFees.CARD_NOT_PRESENT) {
    if (vmcdFees.isSplitRate) {
      array.push({
        brand: 'VI_MC_DI',
        cardPresence: { type: 'CARD_PRESENT' },
        funding: { type: 'CREDIT' },
        fee: {
          percent: formatRateForAPI(vmcdFees.CARD_PRESENT.credit?.rate),
          fixedAmount: vmcdFees.CARD_PRESENT.credit?.rate
        },
        model: model
      })
      array.push({
        brand: 'VI_MC_DI',
        cardPresence: { type: 'CARD_PRESENT' },
        funding: { type: 'DEBIT_PREPAID' },
        fee: {
          percent: formatRateForAPI(vmcdFees.CARD_PRESENT.debit?.rate),
          fixedAmount: vmcdFees.CARD_PRESENT.debit?.fee
        },
        model: model
      })
    } else {
      array.push({
        brand: 'VI_MC_DI',
        cardPresence: { type: 'CARD_PRESENT' },
        funding: { type: 'CREDIT_DEBIT_PREPAID' },
        fee: {
          percent: formatRateForAPI(vmcdFees.CARD_PRESENT?.rate),
          fixedAmount: vmcdFees.CARD_PRESENT?.fee
        },
        model: model
      })
    }

    array.push({
      brand: 'VI_MC_DI',
      cardPresence: { type: 'CARD_NOT_PRESENT' },
      funding: { type: 'CREDIT_DEBIT_PREPAID' },
      fee: {
        percent: formatRateForAPI(vmcdFees.CARD_NOT_PRESENT?.rate),
        fixedAmount: vmcdFees.CARD_NOT_PRESENT?.fee
      },
      model: model
    })
  }

  return array
}

export type cardTransactionFee = {
  model: Model
  fee: {
    percent: number
    fixedAmount: number
  }
  brand: string
  funding: {
    type: string
    values: string[]
  }
  cardPresence: {
    type: string
    values: string[]
  }
  name: string
  code: string
}

const checkCombinedRates = (fees: cardTransactionFee[]) => {
  return fees.some(
    (fee) => fee.cardPresence.type === 'CARD_PRESENT_OR_NOT_PRESENT'
  )
}

const formatRate = (rate: number | undefined) => {
  if (!rate) {
    return 0
  } else return rate * 100
}

const checkSplitRates = (fees: cardTransactionFee[]) => {
  return fees.some((fee) => fee.funding.type === 'CREDIT')
}

const getCardNotPresent = (fee?: cardTransactionFee) => {
  return { rate: formatRate(fee?.fee.percent), fee: fee?.fee.fixedAmount ?? 0 }
}

const getCPCNP = (fee?: cardTransactionFee) => {
  return {
    rate: formatRate(fee?.fee.percent),
    fee: fee?.fee.fixedAmount ?? 0
  }
}

export const formatExistingCardTransactionFeesAmex = (
  cardTransactionFees: cardTransactionFee[]
) => {
  const getCardPresent = (fees?: cardTransactionFee) => {
    return {
      rate: formatRate(fees?.fee.percent),
      fee: fees?.fee.fixedAmount ?? 0
    }
  }

  const isCombinedRates = checkCombinedRates(cardTransactionFees)

  const formattedFees: CardProcessorConfigAMEX = {
    combinedRates: isCombinedRates,
    CARD_PRESENT: isCombinedRates
      ? { fee: 0, rate: 0 }
      : getCardPresent(
          cardTransactionFees.find(
            (fee) => fee.cardPresence.type === 'CARD_PRESENT'
          )
        ),
    CARD_NOT_PRESENT: isCombinedRates
      ? { fee: 0, rate: 0 }
      : getCardNotPresent(
          cardTransactionFees.find(
            (fee) => fee.cardPresence.type === 'CARD_NOT_PRESENT'
          )
        ),
    CP_CNP: isCombinedRates
      ? getCPCNP(
          cardTransactionFees.find(
            (fee) => fee.cardPresence.type === 'CARD_PRESENT_OR_NOT_PRESENT'
          )
        )
      : { fee: 0, rate: 0 }
  }

  return formattedFees
}

export const formatExistingCardTransactionFeesVMCD = (
  cardTransactionFees: cardTransactionFee[]
) => {
  const getCardPresent = (
    isSplitRates: boolean,
    fees: cardTransactionFee[]
  ) => {
    const creditFee = fees.filter(
      (fee: cardTransactionFee) => fee.funding.type === 'CREDIT'
    )[0]
    const debitFee = fees.filter(
      (fee: cardTransactionFee) => fee.funding.type === 'DEBIT_PREPAID'
    )[0]

    const standardFee = fees?.at(0)

    return {
      rate: isSplitRates ? 0 : formatRate(standardFee?.fee.percent),
      fee: isSplitRates ? 0 : standardFee?.fee.fixedAmount ?? 0,
      credit: {
        rate: isSplitRates ? formatRate(creditFee.fee.percent) : 0,
        fee: isSplitRates ? creditFee.fee.fixedAmount ?? 0 : 0
      },
      debit: {
        rate: isSplitRates ? formatRate(debitFee.fee.percent) : 0,
        fee: isSplitRates ? debitFee.fee.fixedAmount ?? 0 : 0
      }
    }
  }

  const isCombinedRates = checkCombinedRates(cardTransactionFees)
  const isSplitRates = checkSplitRates(cardTransactionFees)

  const cardPresentVMCDFees = cardTransactionFees?.filter(
    (fee: cardTransactionFee) => fee.cardPresence.type === 'CARD_PRESENT'
  )

  const cardPresentFees =
    cardPresentVMCDFees === undefined ? [] : cardPresentVMCDFees

  const formattedFees: CardProcessorConfigVCMD = {
    combinedRates: isCombinedRates,
    isSplitRate: isSplitRates,
    CARD_PRESENT: isCombinedRates
      ? {
          rate: 0,
          fee: 0,
          credit: { rate: 0, fee: 0 },
          debit: { rate: 0, fee: 0 }
        }
      : getCardPresent(isSplitRates, cardPresentFees),
    CARD_NOT_PRESENT: isCombinedRates
      ? { rate: 0, fee: 0 }
      : getCardNotPresent(
          cardTransactionFees?.find(
            (fee) => fee.cardPresence.type === 'CARD_NOT_PRESENT'
          )
        ),
    CP_CNP: isCombinedRates
      ? getCPCNP(
          cardTransactionFees?.find(
            (fee) => fee.cardPresence.type === 'CARD_PRESENT_OR_NOT_PRESENT'
          )
        )
      : { rate: 0, fee: 0 }
  }

  return formattedFees
}

const buildRequest = (formValues: TemplateForm) => {
  const data = formatCardTransactionFees(
    formValues.cardProcessorSettingsAMEX,
    formValues.cardProcessorSettingsVI_MC_DI,
    formValues.model
  )

  const result = {
    type: formValues.type,
    description: formValues.description,
    channel: formValues.channel,
    paymentMethod: 'CARD',
    currency: 'USD',
    criteria: {
      gpvRanges: formValues.gpv,
      countryCodes: [formValues.countryCodes],
      qualifiers: formValues.qualifiers,
      salesChannels: formValues.salesChannels
    },
    effectiveDate: formValues.effectiveStartDate!.toString(),
    otherFees: [
      {
        type: 'MONTHLY_FEE',
        frequency: 'MONTHLY',
        fixedAmount: formValues.monthlyCCFee
      }
    ],
    cardTransactionFees: data
  }

  return result
}

export const buildCreateRequest = (
  mutation: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    {
      params: TemplateRequestBody
      targetStatus: string
      userGuid?: string
    },
    unknown
  >,
  formValues: TemplateForm,
  userGuid: string
) => {
  const requestBody = buildRequest(formValues)
  mutation({
    params: requestBody,
    userGuid: userGuid,
    targetStatus: formValues.targetStatus
  })
}

export const buildEditRequest = (
  mutation: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    {
      params: TemplateRequestBody
      templateId: string
      targetStatus: string
      userGuid?: string
    },
    unknown
  >,
  formValues: TemplateForm,
  userGuid: string,
  templateId: string
) => {
  const requestBody = buildRequest(formValues)
  mutation({
    targetStatus: formValues.targetStatus,
    params: requestBody,
    userGuid: userGuid,
    templateId: templateId
  })
}
