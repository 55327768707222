import * as React from 'react'
import { CheckboxField, NumberInputField } from '@toasttab/buffet-pui-forms'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import { CardProccesorInput } from '../CardProcessorInput'
import { Model } from '../PricingTemplateBaseCard'
import cx from 'classnames'

export interface CardProcessorSectionProps {
  brand: string
  brandDisplayName?: string
  channelClassifications: string[]
  isCombinedRate?: boolean
  disabled: boolean
  model: Model
  isSplitRate?: boolean
}

/**
 * CardProcessorSection component
 */
export const CardProcessorSection = ({
  brand,
  brandDisplayName,
  channelClassifications,
  disabled,
  model,
  isCombinedRate,
  isSplitRate
}: CardProcessorSectionProps) => {
  const [isCombined, setIsCombined] = React.useState(isCombinedRate)

  const trimBrand = (brand: string) => {
    return brand.replaceAll('_', '/')
  }

  const isInterchangePlus = model === 'INTERCHANGE_PLUS'

  return (
    <div className='mt-4'>
      <div className='mb-6 flex items-center'>
        <p className={cx({ 'text-disabled': disabled }, 'font-semibold w-20')}>
          {trimBrand(brandDisplayName ?? brand)}
        </p>
        <CheckboxField
          disabled={disabled}
          checked={isCombined}
          name={`cardProcessorSettings${brand}.combinedRates`}
          onClick={() => {
            setIsCombined(!isCombined)
          }}
          label={
            <div className='flex items-center'>
              <p className='mr-2'>Combine rates</p>
              <InfoTooltip disabled={disabled} size='sm' cropToIcon>
                Combine into one rate for card present and card not present
              </InfoTooltip>
            </div>
          }
        />
      </div>
      <div className='flex justify-start'>
        {isCombined ? (
          <div className='w-1/2 px-40'>
            <p className='h-10'>CARD PRESENT/CARD NOT PRESENT</p>
            <div className='flex'>
              {isInterchangePlus && <p className='w-20 pt-8'>Cost +</p>}
              <div className='grid grid-cols-2 gap-x-8'>
                <NumberInputField
                  disabled={disabled}
                  suffix='%'
                  decimalScale={4}
                  suffixVariant='bgGray'
                  label='Rate'
                  name={`cardProcessorSettings${brand}.CP_CNP.rate`}
                />
                <NumberInputField
                  disabled={disabled}
                  prefix='¢'
                  prefixVariant='bgGray'
                  label='Fee'
                  name={`cardProcessorSettings${brand}.CP_CNP.fee`}
                />
              </div>
            </div>
          </div>
        ) : (
          channelClassifications.map((channel) => {
            return (
              <CardProccesorInput
                key={channel + brand}
                isInterchangePlus={isInterchangePlus}
                disabled={disabled}
                isSplitRate={isSplitRate}
                channel={channel}
                brand={brand}
              />
            )
          })
        )}
      </div>
    </div>
  )
}
