import * as React from 'react'
import { useFieldFast } from '../useFieldFast'
import {
  SelectItem,
  SelectMultiple,
  SelectMultipleProps,
  SelectOption
} from '@toasttab/buffet-pui-select'
import { RemoveFields } from '@toasttab/buffet-shared-types'
import { FormValuesWithName, TypedName } from '../commonTypes'

export type SelectMultipleFieldProps<
  FormValues extends FormValuesWithName = string,
  TValue extends any = string,
  TItem extends SelectItem<any> = SelectOption<TValue>
> = RemoveFields<SelectMultipleProps<TValue, TItem>, 'onChange' | 'name'> &
  TypedName<FormValues> & {
    onChange?: (value: TValue[]) => void
  }

export const SelectMultipleField = <
  FormValues extends FormValuesWithName = string,
  TValue extends any = string,
  TItem extends SelectItem<any> = SelectOption<TValue>
>(
  props: SelectMultipleFieldProps<FormValues, TValue, TItem>
) => {
  const { onChange, ...rest } = props
  const [{ ...fields }, meta, helpers] = useFieldFast({
    ...rest,
    size: undefined,
    value: props.value as any,
    multiple: true
  })
  const errorText = meta.error
  const invalid = meta.touched && !!errorText
  return (
    <SelectMultiple
      invalid={invalid}
      errorText={errorText}
      {...fields}
      {...rest}
      onChange={(value) => {
        helpers.setTouched(true)
        helpers.setValue(value)
        onChange?.(value)
      }}
    />
  )
}
