import { SelectItem, SelectOption, TruncateOption } from './types'

export function itemToStringFn<V, T extends SelectItem<V>>(
  item: T | null
): string {
  return isSelectOption(item) ? item.label.toString() : (item as string) || ''
}

export type ItemToStringFn<TValue, TItem extends SelectItem<TValue>> = (
  item: TItem | null
) => string

export function itemToValueFn<V, T extends SelectItem<V>>(item: T): V {
  return (isSelectOption(item) ? item.value : item || '') as V
}

export type ItemToValueFn<TValue, TItem extends SelectItem<TValue>> = (
  item: TItem
) => TValue

export const isSelectOption = <
  TValue extends any = string,
  TItem extends SelectItem<any> = SelectOption<TValue>
>(
  item: SelectOption<TValue> | TItem | null
): item is SelectOption<TValue> => Boolean(item) && typeof item === 'object'

export function pickTruncateOption(
  truncateOption: TruncateOption,
  label: string,
  subLabel: string,
  isSelectButton: boolean
) {
  const EMPTY_STRING = ''
  let {
    labelClassName,
    subLabelClassName,
    labelTitle,
    subLabelTitle,
    contentHeader
  } = {
    contentHeader: 'truncate',
    labelClassName: EMPTY_STRING,
    subLabelClassName: EMPTY_STRING,
    labelTitle: EMPTY_STRING,
    subLabelTitle: EMPTY_STRING
  }
  const labelTruncate = isSelectButton ? EMPTY_STRING : 'truncate'

  switch (truncateOption) {
    case 'label':
      labelClassName = labelTruncate
      subLabelClassName = 'whitespace-normal'
      labelTitle = label
      break
    case 'labelAndSubLabel':
      labelClassName = subLabelClassName = labelTruncate
      labelTitle = label
      subLabelTitle = subLabel
      break
    case 'subLabel':
      labelClassName = 'whitespace-normal'
      subLabelClassName = labelTruncate
      subLabelTitle = subLabel
      break
    default:
      contentHeader = EMPTY_STRING
      break
  }
  return {
    labelClassName,
    subLabelClassName,
    labelTitle,
    subLabelTitle,
    contentHeader
  }
}

export function shouldTruncate(
  truncateOption: TruncateOption,
  label: string,
  subLabel: string
) {
  switch (truncateOption) {
    case 'label':
      return !!label
    case 'subLabel':
      return !!subLabel
    case 'labelAndSubLabel':
      return !!label || !!subLabel
    default:
      return false
  }
}
