import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  description: yup.string().required(),
  gpv: yup
    .array()
    .of(yup.string())
    .min(1, 'Must have at least one option selected'),
  type: yup.string().required(),
  salesChannels: yup
    .array()
    .of(yup.string())
    .min(1, 'Must have at least one option selected'),
  countryCodes: yup.string().required(),
  effectiveStartDate: yup.date().required(),
  monthlyCCFee: yup.number().min(0),
  cardProcessorSettingsAMEX: yup.object({
    combinedRates: yup.boolean(),
    CARD_PRESENT: yup.object({
      rate: yup.number().min(0),
      fee: yup.number().min(0)
    }),
    CARD_NOT_PRESENT: yup.object({
      rate: yup.number().min(0),
      fee: yup.number().min(0)
    }),
    CARD_PRESENT_CARD_NOT_PRESENT: yup.object({
      rate: yup.number().min(0),
      fee: yup.number().min(0)
    })
  }),
  cardProcessorSettingsVI_MC_DI: yup.object({
    combinedRates: yup.boolean(),
    CARD_PRESENT: yup.object({
      rate: yup.number().min(0),
      fee: yup.number().min(0),
      credit: yup.object({
        rate: yup.number().min(0),
        fee: yup.number().min(0)
      }),
      debit: yup.object({
        rate: yup.number().min(0),
        fee: yup.number().min(0)
      })
    }),
    CARD_NOT_PRESENT: yup.object({
      rate: yup.number().min(0),
      fee: yup.number().min(0)
    }),
    CARD_PRESENT_CARD_NOT_PRESENT: yup.object({
      rate: yup.number().min(0),
      fee: yup.number().min(0)
    })
  })
})
