import * as React from 'react'
import { PricingTemplatesPage } from '@local/pricing-template-table'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { PricingTemplatePage } from '@local/pricing-template-card'

export const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  })

  return (
    <QueryClientProvider client={queryClient}>
      <div className='p-4 space-y-2 type-default' data-testid='app-asdf'>
        <BrowserRouter basename='/toast/admin/payments/pricing-templates-admin'>
          <Routes>
            <Route path='/' element={<PricingTemplatesPage />} />
            <Route path='/base' element={<PricingTemplatePage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  )
}
