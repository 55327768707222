import * as React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import { Status, getBadgeColour } from '@local/pricing-template-table'
import { Badge } from '@toasttab/buffet-pui-badge'
import { Button } from '@toasttab/buffet-pui-buttons'

export interface TemplateStatusModalProps {
  testId?: string | number
  isModalOpen: boolean
  closeModalHandler: () => void
}

type StatusInfo = {
  status: Status
  desc: string
}

const StatusExplanation = ({ statusInfo }: { statusInfo: StatusInfo }) => {
  return (
    <div className='flex justify-start items-center mt-3 mb-6'>
      <Badge
        className='mr-10 min-w-20'
        size='lg'
        color={getBadgeColour(statusInfo.status)}
      >
        {statusInfo.status}
      </Badge>
      <p>{statusInfo.desc}</p>
    </div>
  )
}

const Statuses: StatusInfo[] = [
  {
    status: 'DRAFT',
    desc: 'New pricing templates that have yet to be submitted for review.'
  },
  {
    status: 'IN_REVIEW',
    desc: 'Pricing templates which have been submitted and are DRAFT review.'
  },
  {
    status: 'APPROVED',
    desc: 'Pricing templates which have been approved'
  }
]

/**
 * TemplateStatusModal component
 */
export const TemplateStatusModal = (props: TemplateStatusModalProps) => {
  return (
    <Modal data-testid={props.testId} isOpen={props.isModalOpen}>
      <ModalHeader>
        <p>What does each status mean?</p>
      </ModalHeader>
      <ModalBody>
        {Statuses.map((statusInfo) => {
          return (
            <StatusExplanation
              key={statusInfo.status}
              statusInfo={statusInfo}
            />
          )
        })}
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => props.closeModalHandler()}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}
