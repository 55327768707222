import * as React from 'react'
import { useField } from 'formik'
import {
  NumberInput,
  NumberInputProps,
  NumberFormatValues
} from '@toasttab/buffet-pui-text-input'
import { RemoveFields } from '@toasttab/buffet-shared-types'
import { FormValuesWithName, TypedName } from '../commonTypes'

export type NumberInputFieldProps<
  FormValues extends FormValuesWithName = string
> = RemoveFields<NumberInputProps, 'name'> & TypedName<FormValues>

export const NumberInputField = React.forwardRef<
  HTMLInputElement,
  NumberInputFieldProps
>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fields, meta, helpers] = useField({
    ...props,
    size: undefined,
    prefix: undefined,
    onChange: undefined
  })
  const errorText = meta.error
  const invalid = meta.touched && !!errorText
  // Conflicting `onChange` definition in `NumberInput`
  const onValueChanged = (value: NumberFormatValues) => {
    const actualValue =
      typeof value.floatValue === 'number'
        ? props.allowLeadingZeros
          ? value.value
          : value.floatValue
        : ''
    helpers.setValue(actualValue)
    props.onChange?.(value)
  }

  return (
    <NumberInput
      errorText={errorText}
      invalid={invalid}
      {...fields}
      {...props}
      ref={ref}
      onChange={onValueChanged}
    />
  )
}) as <FormValues extends FormValuesWithName = string>(
  props: NumberInputFieldProps<FormValues> & {
    ref?: React.ForwardedRef<HTMLInputElement>
  }
) => JSX.Element
