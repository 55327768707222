import * as React from 'react'
import {
  Body,
  Head,
  HeadingCell,
  Row,
  Table,
  Cell
} from '@toasttab/buffet-pui-table'
import { Badge } from '@toasttab/buffet-pui-badge'
import { useNavigate } from 'react-router-dom'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { SelectMultiple } from '@toasttab/buffet-pui-select'
import { LoadingWrapper } from '@local/common'
import { Formats, format } from '@toasttab/buffet-pui-date-utilities'
import { usePricingTemplateTable } from '../usePricingTemplateTable'
import {
  getBadgeColour,
  getGPVBadgeColour,
  getGPVRangeLabel
} from '../utilities'

export interface PricingTemplateTableProps {
  testId?: string | number
  isMocked?: boolean
}

export type Status = 'DRAFT' | 'IN_REVIEW' | 'APPROVED' | 'ARCHIVED'

type TableRow = {
  description: string
  effectiveDate?: string
  type: 'STANDARD' | 'CUSTOM'
  channel: string
  status: Status
  criteria: {
    gpvRanges: string[]
  }
  qualifiers: string[]
  id: string
}

/**
 * PricingTemplateTable component
 */
export const PricingTemplateTable = ({
  isMocked = false,
  testId = 'pricing-template-table'
}: PricingTemplateTableProps) => {
  const navigate = useNavigate()
  const [filter, setFilter] = React.useState(['APPROVED', 'IN_REVIEW', 'DRAFT'])

  const { data, isLoading, isError } = usePricingTemplateTable(isMocked)

  return (
    <CardContainer data-testid={testId}>
      <LoadingWrapper isError={isError} isLoading={isLoading}>
        <div className='flex justify-end'>
          <SelectMultiple
            value={filter}
            hideLabel
            placeholder='Filter by status'
            label={'filter'}
            options={['APPROVED', 'IN_REVIEW', 'DRAFT']}
            containerClassName='mb-4'
            enableSelectAll
            onChange={(vals) => {
              setFilter(vals)
            }}
          />
        </div>
        <Table>
          <Head>
            <Row>
              <HeadingCell>Description</HeadingCell>
              <HeadingCell>Template ID</HeadingCell>
              <HeadingCell>GPV</HeadingCell>
              <HeadingCell>Effective date</HeadingCell>
              <HeadingCell>Type</HeadingCell>
              <HeadingCell>Channel</HeadingCell>
              <HeadingCell>Status</HeadingCell>
            </Row>
          </Head>
          <Body>
            {data?.data
              .filter((row: TableRow) => filter.includes(row.status))
              .map((row: TableRow) => {
                return (
                  <Row
                    className='hover:cursor-pointer hover:bg-darken-12'
                    onClick={() => navigate('/base', { state: { id: row.id } })}
                    key={row.description + row.effectiveDate?.toString()}
                  >
                    <Cell>{row.description}</Cell>
                    <Cell>{row.id}</Cell>
                    <Cell className='flex'>
                      {row.criteria.gpvRanges
                        .sort()
                        .reverse()
                        .map((gpv: string) => {
                          return (
                            <Badge
                              key={row.id + gpv}
                              color={getGPVBadgeColour(gpv)}
                              className='mx-1'
                            >
                              {getGPVRangeLabel(gpv)}
                            </Badge>
                          )
                        })}
                    </Cell>
                    <Cell>
                      {format(
                        new Date(row.effectiveDate!),
                        Formats.date.medium
                      )?.toString()}
                    </Cell>
                    <Cell>{row.type}</Cell>
                    <Cell>{row.channel}</Cell>
                    <Cell>
                      <Badge color={getBadgeColour(row.status)}>
                        {row.status}
                      </Badge>
                    </Cell>
                  </Row>
                )
              })}
          </Body>
        </Table>
      </LoadingWrapper>
    </CardContainer>
  )
}
