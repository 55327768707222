import { dateFnsFormat, dateTokenFormat } from '../date-utilities'
import { Locale, getLocale } from '@toasttab/buffet-pui-locale-utilities'
// Locales selected from: https://github.com/date-fns/date-fns/tree/master/src/locale

// Custom locale overrides
import { Formats } from './formats'
import { DateRange } from './dateRange'

export type DateFormatOptions = {
  locale?: Locale
  timeZone?: string
}

const getTimeZoneName = (
  date: Date,
  locale: Locale,
  type: string,
  timezone?: string
): string | null => {
  //@ts-ignore
  return new Intl.DateTimeFormat(locale, {
    //@ts-ignore
    timeZoneName: type,
    timeZone: timezone
  })
    .formatToParts(date)
    .find((part) => part.type === 'timeZoneName')?.value
}

const dateFormat = (
  date: Date,
  formatString: string | Intl.DateTimeFormatOptions,
  locale: Locale,
  timeZone?: string,
  timeZoneName?: string
) => {
  if (timeZone && typeof formatString === 'object') {
    const result = timeZoneName
      ? getTimeZoneName(date, locale, timeZoneName, timeZone)
      : dateTokenFormat(date, { timeZone: timeZone, ...formatString }, locale)
    return result
  } else if (timeZone && typeof formatString === 'string') {
    // deal with timezone first
    const tZDate = dateTokenFormat(date, { timeZone: timeZone }, locale)
    //then format to specified formatString
    return dateTokenFormat(tZDate, formatString)
  } else if (
    typeof formatString === 'object' &&
    Object.keys(formatString).length === 1 &&
    formatString.timeZoneName
  ) {
    //To deal with timezones we are using date-fns
    return dateFnsFormat(date, timeZoneName === 'long' ? 'zzzz' : 'zzz')
  } else if (timeZoneName) {
    //@ts-ignore
    return dateTokenFormat(date, formatString, locale, timeZoneName)
  }
  return dateTokenFormat(date, formatString, locale)
}

/* Format Date */

/**
 * format - Return a formatted date
 * @param {Date} date - A date object
 * @param {string} formatString - A format string, any Unicode date token string or a `Formats` value
 * @param {Locale | DateFormatOptions} locale - A locale (string) such as `en-US` or object containing options such as locale and timeZone
 */
export const format = (
  date: Date,
  formatString: string | Intl.DateTimeFormatOptions = Formats.date.medium,
  options?: Locale | DateFormatOptions
): string | null => {
  if (!(date instanceof Date)) {
    return null
  }
  // get locale
  let locale = getLocale()
  if (typeof options === 'string') {
    locale = options
  }
  if (typeof options === 'object' && options.locale) {
    locale = options.locale
  }

  //get timezone
  const timeZone = typeof options === 'object' ? options.timeZone : undefined

  //get timezone name short/long
  const timeZoneName =
    typeof formatString === 'object' && formatString.timeZoneName
      ? formatString.timeZoneName
      : undefined

  try {
    return dateFormat(date, formatString, locale, timeZone, timeZoneName)
  } catch (e) {
    return null
  }
}

/**
 * createFormat - Creates a formatter function for formatting dates
 * @param {string} formatString - A format string, any Unicode date token string or a `Formats` value
 * @param {Locale} locale - A locale (string) such as `en-US`
 * @param {DateFormatOptions} options - Options such as timeZone
 */
export const createFormat =
  (
    formatString: string | Intl.DateTimeFormatOptions = Formats.date.medium,
    options?: Locale | DateFormatOptions
  ) =>
  (date: Date, optionsOverride?: Locale | DateFormatOptions): string | null => {
    return format(
      date,
      formatString,
      optionsOverride ? optionsOverride : options
    )
  }

/* Format Date Range */

/**
 * formatRange - Present formatted date ranges
 * @param {DateRange} - An object of type DateRange
 * @param {string} formatString - A format string, any Unicode date token string or a `Formats` value
 * @param {Locale | DateFormatOptions} options - A locale (string) such as `en-US` or Options such as timeZone
 */
export const formatRange = (
  date: DateRange,
  formatString: string | Intl.DateTimeFormatOptions = Formats.date.medium,
  options?: Locale | DateFormatOptions
) => {
  if (!date || !date.from) {
    return null
  }
  const from = format(date.from, formatString, options)
  if (date.to && date.to !== date.from) {
    const to = format(date.to, formatString, options)
    return `${from} - ${to}`
  }
  return from
}

/**
 * createFormat - Creates a formatter function for formatting date ranges
 * @param {string} formatString - A format key, any Unicode date token string or a `Formats` value
 * @param {Locale | DateFormatOptions} options - A locale (string) such as `en-US` or Options such as timeZone
 */
export const createFormatRange =
  (
    formatString: string | Intl.DateTimeFormatOptions = Formats.date.medium,
    options?: Locale | DateFormatOptions
  ) =>
  (date: DateRange, optionsOverride?: Locale | DateFormatOptions) => {
    if (!date || !date.from) {
      return null
    }
    return formatRange(
      date,
      formatString,
      optionsOverride ? optionsOverride : options
    )
  }
