import { useQuery } from '@tanstack/react-query'
import { retrievePricingTemplates } from '../../../../src/app/api'

/**
 * usePricingTemplateTable
 */
export const usePricingTemplateTable = (isMocked: boolean) => {
  const { data, isLoading, isError } = useQuery(
    ['TEMPLATES'],
    retrievePricingTemplates,
    { enabled: !isMocked }
  )

  if (isMocked) {
    return {
      data: {
        data: [
          {
            description: 'Test Row One',
            effectiveDate: new Date().toISOString(),
            type: 'STANDARD',
            channel: '',
            status: 'DRAFT',
            criteria: {
              gpvRanges: ['SMALL', 'MEDIUM']
            },
            qualifiers: ['SURCHARGING'],
            id: '1234'
          },
          {
            description: 'Test Row Two',
            effectiveDate: new Date().toISOString(),
            type: 'STANDARD',
            channel: '',
            status: 'APPROVED',
            criteria: {
              gpvRanges: ['SMALL', 'LARGE']
            },
            qualifiers: ['SURCHARGING'],
            id: '2345'
          },
          {
            description: 'Test Row Three',
            effectiveDate: new Date().toISOString(),
            type: 'STANDARD',
            channel: '',
            status: 'IN_REVIEW',
            criteria: {
              gpvRanges: ['SMALL', 'EXTRA_LARGE']
            },
            qualifiers: ['SURCHARGING'],
            id: '3456'
          },
          {
            description: 'Test Row Four',
            effectiveDate: new Date().toISOString(),
            type: 'STANDARD',
            channel: '',
            status: 'DRAFT',
            criteria: {
              gpvRanges: ['SMALL']
            },
            qualifiers: ['SURCHARGING'],
            id: '4567'
          }
        ]
      },
      isLoading: false,
      isError: false
    }
  }

  return { data, isLoading, isError }
}
