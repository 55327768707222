import * as React from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { PricingTemplateTable } from '../PricingTemplateTable'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useNavigate } from 'react-router-dom'

export interface PricingTemplatesPageProps {
  testId?: string | number
  isMocked?: boolean
}

/**
 * PricingTemplatesPage component
 */
export const PricingTemplatesPage = ({
  testId = 'pricing-templates-page',
  isMocked = false
}: PricingTemplatesPageProps) => {
  const navigate = useNavigate()

  const newTemplateButtonClick = () => {
    navigate('/base')
  }

  return (
    <CardContainer testId={testId}>
      <div className='flex justify-between my-4'>
        <p className='type-headline-4 font-semibold'>Pricing templates admin</p>
        <Button onClick={() => newTemplateButtonClick()}>New +</Button>
      </div>
      <PricingTemplateTable isMocked={isMocked} />
    </CardContainer>
  )
}
