import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { MerryGoRound, SIZE } from '@toasttab/buffet-pui-loading-indicators'
import * as React from 'react'

export interface LoadingWrapperProps {
  testId?: string | number
  isError: boolean
  isLoading: boolean
  children: React.ReactNode
}

/**
 * LoadingWrapper component
 */
export const LoadingWrapper = (props: LoadingWrapperProps) => {
  return (
    <>
      {props.isError ? (
        <ErrorPage500 testId='loading-wrapper-error-page' />
      ) : props.isLoading ? (
        <div
          className='w-full flex justify-center items-center'
          style={{ minHeight: '551px' }}
        >
          <MerryGoRound
            testId={'loading-wrapper-loading-element'}
            size={SIZE.xxl}
          />
        </div>
      ) : (
        props.children
      )}
    </>
  )
}
