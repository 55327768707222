import * as React from 'react'
import cx from 'classnames'

import { ArrowDropDownIcon } from '@toasttab/buffet-pui-icons'

import {
  SelectButtonProps,
  getToggleButtonSizeClass,
  getToggleButtonTextColor,
  getToggleButtonHeightClass
} from './SelectButton'
import { Border } from './Border'

export const SelectButtonContained = React.forwardRef<
  HTMLButtonElement,
  SelectButtonProps
>(
  (
    {
      testId,
      disabled,
      selected,
      inlineBlock,
      isOpen,
      disableFocusShadow,
      iconLeft,
      children,
      containerClassName,
      containerRef,
      customHeight,
      size = 'auto',
      align = 'center',
      invalid,
      transparentBackground,
      borderRadiusClassName,
      changed = false,
      label,
      hideLabel,
      ...props
    },
    ref
  ) => {
    const toggleButtonSizeClass = getToggleButtonSizeClass(size)
    const toggleButtonIconColor = disabled ? 'text-disabled' : 'text-secondary'
    return (
      <label>
        <Border
          testId={testId}
          inlineBlock={inlineBlock}
          changed={changed}
          transparentBackground={transparentBackground}
          className={cx(
            containerClassName,
            !customHeight && getToggleButtonHeightClass(size),
            {
              'hover:shadow-input-hover': !disabled
            }
          )}
          isOpen={isOpen}
          disableFocusShadow={disableFocusShadow} // when search is enabled we show the search box as focused rather than the outer border
          ref={containerRef}
          borderRadiusClassName={borderRadiusClassName}
          borderInnerClassName={cx({
            'hover:border-gray-75': !disabled,
            'focus-within:border-gray-125 hover:focus-within:border-gray-125 focus-within:shadow-focus-outline':
              !disabled && !disableFocusShadow,
            'border-gray-125 shadow-focus-outline': isOpen
          })}
        >
          <button
            data-testid={`${testId}-button`}
            type='button'
            disabled={disabled}
            className={cx(
              'flex text-left items-center',
              'pl-3 pr-2 rounded-input',
              'type-default font-normal',
              'focus:outline-none',
              align === 'center' ? 'items-center' : 'items-top',
              getToggleButtonTextColor(selected, disabled),
              {
                'w-full': !inlineBlock,
                'cursor-default': disabled
              }
            )}
            ref={ref}
            {...props}
          >
            {iconLeft && (
              <div
                className={cx(
                  toggleButtonSizeClass,
                  toggleButtonIconColor,
                  'h-6 flex items-center mr-2'
                )}
              >
                {iconLeft}
              </div>
            )}
            <div className='flex-grow truncate'>
              {!!label && (
                <div
                  className={cx(
                    'input-label-text',
                    disabled ? 'text-disabled' : 'input-label-color',
                    hideLabel && 'sr-only',
                    'mb-1'
                  )}
                >
                  {label}
                </div>
              )}
              <div className='h-5' style={{ lineHeight: '1.25rem' }}>
                {children}
              </div>
            </div>
            <ArrowDropDownIcon
              className={cx(
                toggleButtonSizeClass,
                toggleButtonIconColor,
                'h-6 items-center pl-2 flex-none'
              )}
              accessibility='decorative'
            />
          </button>
        </Border>
      </label>
    )
  }
)
