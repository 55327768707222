import * as React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

export interface BorderProps extends TestIdentifiable {
  invalid?: boolean
  disabled?: boolean
  inlineBlock?: boolean
  changed?: boolean
  transparentBackground?: boolean
  className?: string
  isOpen?: boolean
  disableFocusShadow?: boolean
  borderRadiusClassName?: string
  borderInnerClassName?: string
}

export const Border = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<BorderProps>
>(
  (
    {
      children,
      className,
      isOpen,
      invalid,
      disabled,
      inlineBlock,
      changed,
      transparentBackground,
      disableFocusShadow,
      testId,
      borderRadiusClassName,
      borderInnerClassName
    },
    ref
  ) => {
    const borderRadius = cx('rounded-input', borderRadiusClassName)

    return (
      <div
        data-testid={`${testId}-border-outer`}
        className={cx(
          'p-0',
          borderRadius,
          {
            'inline-block': inlineBlock,
            'shadow-focus': isOpen && !disableFocusShadow,
            'focus-within:shadow-focus-outline': !disableFocusShadow
          },
          changed
            ? 'bg-primary-0'
            : transparentBackground
            ? 'bg-transparent'
            : 'bg-white',
          className
        )}
      >
        <div
          data-testid={`${testId}-border-inner`}
          ref={ref}
          className={cx(
            'p-0',
            'flex items-center border h-full',
            borderRadius,
            borderInnerClassName,
            {
              'border-error': invalid && !isOpen,
              'border-disabled': disabled
            }
          )}
        >
          <div
            className='flex -m-px'
            style={{ width: 'calc(100% + 2px)', height: 'calc(100% + 2px)' }}
          >
            {children}
          </div>
        </div>
      </div>
    )
  }
)
