import * as React from 'react'
import { useField } from 'formik'
import { TextInput, TextInputProps } from '@toasttab/buffet-pui-text-input'
import { RemoveFields } from '@toasttab/buffet-shared-types'
import { FormValuesWithName, TypedName } from '../commonTypes'

export type TextInputFieldProps<
  FormValues extends FormValuesWithName = string
> = RemoveFields<TextInputProps, 'name'> &
  RemoveFields<
    React.InputHTMLAttributes<HTMLInputElement>,
    'size' | 'prefix' | 'value' | 'name'
  > &
  TypedName<FormValues>

export const TextInputField = React.forwardRef<
  HTMLInputElement,
  TextInputFieldProps
>((props, ref) => {
  const { onChange, ...restProps } = props
  const [field, meta, helpers] = useField({
    ...restProps,
    size: undefined,
    prefix: undefined
  })

  const errorText = meta.error
  const invalid = meta.touched && !!errorText

  return (
    <TextInput
      ref={ref}
      invalid={invalid}
      errorText={errorText}
      {...field}
      {...restProps}
      onChange={(e) => {
        helpers.setTouched(true)
        helpers.setValue(e.target.value)
        onChange?.(e)
      }}
    />
  )
}) as <FormValues extends FormValuesWithName = string>(
  props: TextInputFieldProps<FormValues> & {
    ref?: React.ForwardedRef<HTMLInputElement>
  }
) => JSX.Element
