import * as React from 'react'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { Status } from '@local/pricing-template-table'
import { DeleteIcon } from '@toasttab/buffet-pui-icons'

export interface TemplateFormButtonGroupProps {
  testId?: string | number
  status: Status
  buttonClickHandler: (arg0: Status) => void
}

/**
 * TemplateFormButtonGroup component
 */
export const TemplateFormButtonGroup = (
  props: TemplateFormButtonGroupProps
) => {
  const getButtons = (status: typeof props.status) => {
    switch (status) {
      case 'APPROVED':
        return (
          <Button onClick={() => props.buttonClickHandler('ARCHIVED')}>
            Deactivate
          </Button>
        )
      case 'IN_REVIEW':
        return (
          <>
            <Button onClick={() => props.buttonClickHandler('DRAFT')}>
              Return to draft
            </Button>
            <Button onClick={() => props.buttonClickHandler('APPROVED')}>
              Approve
            </Button>
          </>
        )
      default:
        return (
          <>
            <Button onClick={() => props.buttonClickHandler('DRAFT')}>
              Save only
            </Button>
            <Button onClick={() => props.buttonClickHandler('IN_REVIEW')}>
              Save and send to review
            </Button>
          </>
        )
    }
  }

  return (
    <ButtonGroup className='flex justify-end'>
      {props.status !== 'APPROVED' && (
        <Button
          variant='destructive'
          iconLeft={<DeleteIcon accessibility='decorative' />}
          onClick={() => props.buttonClickHandler('ARCHIVED')}
        >
          Delete
        </Button>
      )}
      {getButtons(props.status)}
    </ButtonGroup>
  )
}
