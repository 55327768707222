import * as React from 'react'
import cx from 'classnames'

import { ArrowDropDownIcon } from '@toasttab/buffet-pui-icons'

import {
  SelectButtonProps,
  getToggleButtonSizeClass,
  getToggleButtonTextColor,
  getToggleButtonHeightClass
} from './SelectButton'
import { Border } from './Border'

/**
 * This is very similar to implementation details for the
 * toggle button of the Select and SelectMultiple components
 * and I have _some_ interest in trying to extract this / provide this
 * from the dropdowns package.
 */
export const SelectButtonDefault = React.forwardRef<
  HTMLButtonElement,
  SelectButtonProps
>(
  (
    {
      testId,
      disabled,
      selected,
      inlineBlock,
      isOpen,
      disableFocusShadow,
      iconLeft,
      children,
      containerClassName,
      containerRef,
      customHeight,
      size = 'auto',
      align = 'center',
      invalid,
      transparentBackground,
      borderRadiusClassName,
      changed = false,
      hideLabel,
      ...props
    },
    ref
  ) => {
    const toggleButtonSizeClass = getToggleButtonSizeClass(size)
    const toggleButtonIconColor = disabled ? 'text-disabled' : 'text-secondary'
    return (
      <Border
        testId={testId}
        inlineBlock={inlineBlock}
        changed={changed}
        transparentBackground={transparentBackground}
        className={cx(
          containerClassName,
          !customHeight && getToggleButtonHeightClass(size)
        )}
        isOpen={isOpen}
        disableFocusShadow={disableFocusShadow} // when search is enabled we show the search box as focused rather than the outer border
        ref={containerRef}
        borderRadiusClassName={borderRadiusClassName}
        borderInnerClassName={cx({
          'hover:border-primary-75 hover:shadow-input-hover': !disabled,
          'focus-within:border-primary-75 focus-within:shadow-focus-within':
            !disabled && !disableFocusShadow,
          'border-primary-75 shadow-focus-within': isOpen
        })}
      >
        <button
          data-testid={`${testId}-button`}
          type='button'
          disabled={disabled}
          className={cx(
            'flex text-left',
            'pl-3 pr-2 rounded-input',
            'type-default font-normal',
            'focus:outline-none',
            align === 'center' ? 'items-center' : 'items-top',
            getToggleButtonTextColor(selected, disabled),
            {
              'w-full': !inlineBlock,
              'cursor-default': disabled
            }
          )}
          ref={ref}
          {...props}
        >
          {iconLeft && (
            <div
              className={cx(
                toggleButtonSizeClass,
                toggleButtonIconColor,
                'h-6 flex items-center mr-2'
              )}
            >
              {iconLeft}
            </div>
          )}
          <div className='flex-grow truncate'>{children}</div>
          <ArrowDropDownIcon
            className={cx(
              toggleButtonSizeClass,
              toggleButtonIconColor,
              'h-6 items-center pl-2 flex-none'
            )}
            accessibility='decorative'
          />
        </button>
      </Border>
    )
  }
)
