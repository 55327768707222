import * as React from 'react'
import cx from 'classnames'

import { BorderProps } from './Border'
import { Prefix } from '../Prefix/Prefix'
import { Suffix } from '../Suffix/Suffix'

export const BorderDefault = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<BorderProps>
>(
  (
    {
      children,
      invalid,
      disabled,
      readOnly,
      prefix,
      prefixVariant = 'bgGray',
      suffix,
      suffixVariant = 'bgGray',
      roundedFull,
      className,
      testId,
      borderRadiusClassName
    },
    ref
  ) => {
    const borderRadius = roundedFull ? 'rounded-full' : 'rounded-input'
    return (
      <div
        data-testid={`${testId}-border-outer`}
        className={cx(
          'relative focus-within:shadow-focus',
          {
            'z-0':
              (prefixVariant === 'bgGray' && prefix) ||
              (suffixVariant === 'bgGray' && suffix)
          },
          'p-0',
          borderRadius,
          className,
          borderRadiusClassName
        )}
      >
        <div
          data-testid={`${testId}-border-inner`}
          ref={ref}
          className={cx(
            'p-0',
            'flex items-center border h-full',
            borderRadius,
            {
              'border-error': invalid,
              'hover:border-primary-75 focus-within:border-primary-75 hover:shadow-input-hover focus-within:shadow-focus-within':
                !disabled && !readOnly,
              'border-disabled': disabled,
              'border-darken-12': readOnly && !disabled
            },
            borderRadiusClassName
          )}
        >
          {prefix && (
            <Prefix
              testId={`${testId}-prefix`}
              variant={prefixVariant}
              disabled={disabled}
              readOnly={readOnly}
            >
              {prefix}
            </Prefix>
          )}
          <div
            className='flex w-full m-px'
            style={{ height: 'calc(100% - 2px)' }}
          >
            {children}
          </div>
          {suffix && (
            <Suffix
              testId={`${testId}-suffix`}
              variant={suffixVariant}
              disabled={disabled}
              roundedClassName={roundedFull ? 'rounded-r-full' : undefined}
              readOnly={readOnly}
            >
              {suffix}
            </Suffix>
          )}
        </div>
      </div>
    )
  }
)
