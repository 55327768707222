import 'systemjs-webpack-interop/auto-public-path'
// IMPORTANT: Do not move or remove the above import. It must be at the top of this file. See https://www.npmjs.com/package/systemjs-webpack-interop#newer-versions-of-webpack
import {
  banquetSingleSpaReact,
  BanquetProps,
  BanquetSingleSpaReact
} from 'banquet-runtime-modules'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { loadTranslations } from '@local/translations'
import { themes } from '@local/themes'
import { App } from './app/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-pta-app',
  rootComponent: App,
  sentry: {
    publicKey: 'a285ae75a4c29889704e68306e43a278@o37442',
    projectId: '4508958869815296'
  },
  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap: BanquetSingleSpaReact['bootstrap'] = [
  loadTranslations,
  ...reactLifecycles.bootstrap
]
export const mount: BanquetSingleSpaReact['mount'] = reactLifecycles.mount
export const unmount: BanquetSingleSpaReact['unmount'] = reactLifecycles.unmount
export const name = 'pricing-templates-admin-spa'
