import * as React from 'react'
import { CheckboxField, NumberInputField } from '@toasttab/buffet-pui-forms'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import cx from 'classnames'

export interface CardProcessorInputProps {
  testId?: string | number
  brand: string
  channel: string
  disabled: boolean
  isInterchangePlus: boolean
  isSplitRate?: boolean
}

export const CardProccesorInput = ({
  channel,
  disabled,
  brand,
  isInterchangePlus,
  isSplitRate
}: CardProcessorInputProps) => {
  const [isSplitRates, setIsSplitRates] = React.useState(
    isSplitRate && brand === 'VI_MC_DI' && channel === 'CARD_PRESENT'
  )

  return (
    <div key={channel} className='w-1/2 mx-40'>
      <div className='flex justify-start items-center h-10'>
        <p className={cx({ 'text-disabled': disabled })}>
          {channel.replaceAll('_', ' ')}
        </p>
        {brand === 'VI_MC_DI' &&
          channel === 'CARD_PRESENT' &&
          !isInterchangePlus && (
            <CheckboxField
              disabled={disabled}
              name={`cardProcessorSettings${brand}.isSplitRate`}
              checked={isSplitRates}
              containerClassName='ml-4'
              onClick={() => {
                setIsSplitRates(!isSplitRates)
              }}
              label={
                <div className='flex items-center'>
                  <p className='mr-2'>Split rates</p>
                  <InfoTooltip disabled={disabled} size='sm' cropToIcon>
                    Split rates into credit and debit
                  </InfoTooltip>
                </div>
              }
            />
          )}
      </div>
      <>
        {!isSplitRates ? (
          <>
            <div className='flex items-center'>
              {isInterchangePlus && (
                <p className={cx({ 'text-disabled': disabled }, 'w-20 pt-6')}>
                  Cost +{' '}
                </p>
              )}{' '}
              <div className='grid grid-cols-2 gap-x-8'>
                <NumberInputField
                  suffix='%'
                  decimalScale={4}
                  suffixVariant='bgGray'
                  label='Rate'
                  disabled={isSplitRates || disabled}
                  name={`cardProcessorSettings${brand}.${channel}.rate`}
                />
                <NumberInputField
                  prefix='¢'
                  prefixVariant='bgGray'
                  label='Fee'
                  disabled={isSplitRates || disabled}
                  name={`cardProcessorSettings${brand}.${channel}.fee`}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='flex items-center justify-between'>
              <p className='w-28'>Credit</p>
              <NumberInputField
                containerClassName='w-28 mr-2'
                suffix='%'
                decimalScale={4}
                suffixVariant='bgGray'
                label='Rate'
                disabled={!isSplitRates || disabled}
                name={`cardProcessorSettings${brand}.${channel}.credit.rate`}
              />
              <NumberInputField
                containerClassName='w-28'
                prefix='¢'
                prefixVariant='bgGray'
                label='Fee'
                disabled={!isSplitRates || disabled}
                name={`cardProcessorSettings${brand}.${channel}.credit.fee`}
              />
            </div>
            <div className='flex items-center justify-between'>
              <p className='w-28'>Debit/Prepaid</p>
              <NumberInputField
                suffix='%'
                containerClassName='w-28 mr-2'
                decimalScale={4}
                suffixVariant='bgGray'
                label='Rate'
                disabled={!isSplitRates || disabled}
                name={`cardProcessorSettings${brand}.${channel}.debit.rate`}
              />
              <NumberInputField
                prefix='¢'
                containerClassName='w-28'
                prefixVariant='bgGray'
                label='Fee'
                disabled={!isSplitRates || disabled}
                name={`cardProcessorSettings${brand}.${channel}.debit.fee`}
              />
            </div>
          </>
        )}
      </>
    </div>
  )
}
