import * as React from 'react'
import { useField } from 'formik'
import { Checkbox, CheckboxProps } from '@toasttab/buffet-pui-checkbox'
import { RemoveFields } from '@toasttab/buffet-shared-types'
import { TypedName, FormValuesWithName } from '../commonTypes'

export type CheckboxFieldProps<FormValues extends FormValuesWithName = string> =
  RemoveFields<CheckboxProps, 'name'> & {
    /** This must match the Formik state key, this also becomes the fields id  */
    checked?: boolean
    /** Value to be set for this checkbox, used in case it is a multiple value checkbox*/
    value?: string
    testId?: string
    /**  Whether or not the multiple values can be selected.  */
    multiple?: boolean
    disabled?: boolean
  } & TypedName<FormValues>

export const CheckboxField = <FormValues extends FormValuesWithName = string>({
  multiple = false,
  onChange,
  onBlur,
  ...otherProps
}: CheckboxFieldProps<FormValues>) => {
  const [field] = useField({
    ...otherProps,
    type: 'checkbox',
    multiple
  })

  return (
    <Checkbox
      {...field}
      {...otherProps}
      onChange={(e) => {
        field.onChange(e)
        onChange?.(e)
      }}
      onBlur={(e) => {
        field.onBlur(e)
        onBlur?.(e)
      }}
    />
  )
}
