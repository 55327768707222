import * as React from 'react'
import {
  FocusActions,
  FocusBody,
  FocusClose,
  FocusHeader,
  FocusView,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { PricingTemplateBaseCard } from '../PricingTemplateBaseCard'
import { useLocation, useNavigate } from 'react-router-dom'
import { LoadingWrapper } from '@local/common'
import { usePricingTemplate } from '../usePricingTemplate'
import {
  cardTransactionFee,
  formatExistingCardTransactionFeesAmex,
  formatExistingCardTransactionFeesVMCD
} from '../PricingTemplateBaseCard/utilities'

export interface PricingTemplatePageProps {
  testId?: string | number
}

const checkModelType = (data: any) => {
  if (
    data.every((d: cardTransactionFee) => {
      return d.model === 'FLAT_RATE'
    })
  ) {
    return 'FLAT_RATE'
  }

  if (
    data.every((d: cardTransactionFee) => {
      return d.model === 'INTERCHANGE_PLUS'
    })
  ) {
    return 'INTERCHANGE_PLUS'
  }

  return 'HYBRID'
}

/**
 * PricingTemplatePage component
 */
export const PricingTemplatePage = (props: PricingTemplatePageProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const isNewCard = location.state === null

  const { data, isError, isLoading } = usePricingTemplate()

  return (
    <FocusView disableMaxWidth testId={props.testId}>
      <LoadingWrapper isError={isError} isLoading={isLoading}>
        <FocusHeader>
          <Title>
            {isNewCard
              ? 'Create a new template'
              : `Edit template - ${data?.data.description}`}
          </Title>
          <FocusActions>
            <FocusClose onClick={() => navigate('/')} />
          </FocusActions>
        </FocusHeader>
        <FocusBody>
          {isLoading ? (
            <div></div>
          ) : (
            <PricingTemplateBaseCard
              isNewCard={isNewCard}
              templateId={location.state?.id}
              templateData={
                isNewCard
                  ? {
                      description: '',
                      gpv: [],
                      qualifiers: [],
                      salesChannels: ['SALESFORCE'],
                      effectiveDate: '',
                      type: 'STANDARD',
                      channel: 'TOAST_PAYMENTS',
                      status: 'DRAFT',
                      monthlyCCFee: 0,
                      cardProcessorSettingsAmex: { combinedRates: false },
                      cardProcessorSettingsVI_MC_DI: {
                        combinedRates: false,
                        isSplitRate: false
                      },
                      countryCodes: ['US'],
                      model: 'FLAT_RATE'
                    }
                  : {
                      description: data?.data.description,
                      gpv: data?.data.criteria.gpvRanges,
                      qualifiers: data?.data.criteria.qualifiers,
                      salesChannels: data?.data.criteria.salesChannels,
                      effectiveDate: data?.data.effectiveDate,
                      type: 'STANDARD',
                      channel: 'TOAST_PAYMENTS',
                      status: data?.data.status,
                      monthlyCCFee:
                        data?.data?.otherFees.length > 0
                          ? data?.data.otherFees[0].fixedAmount
                          : 0,
                      cardProcessorSettingsAmex:
                        formatExistingCardTransactionFeesAmex(
                          data?.data.cardTransactionFees.filter(
                            (fee: cardTransactionFee) => fee.brand === 'AMEX'
                          )
                        ),
                      cardProcessorSettingsVI_MC_DI:
                        formatExistingCardTransactionFeesVMCD(
                          data?.data.cardTransactionFees.filter(
                            (fee: cardTransactionFee) =>
                              fee.brand === 'VI_MC_DI'
                          )
                        ),
                      countryCodes: data?.data.criteria.countryCodes,
                      model: checkModelType(data?.data.cardTransactionFees)
                    }
              }
            />
          )}
        </FocusBody>
      </LoadingWrapper>
    </FocusView>
  )
}
