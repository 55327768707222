import * as React from 'react'
import cx from 'classnames'
import {
  CheckboxIndeterminateIcon,
  CheckboxSelectedIcon,
  CheckboxUnselectedIcon
} from '@toasttab/buffet-pui-icons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { t, loadStrings } from '../defaultStrings'

export type SelectAllProps = TestIdentifiable &
  React.LiHTMLAttributes<HTMLLIElement> & {
    checkStatus: 'checked' | 'unchecked' | 'partial'
    children: React.ReactNode
    itemProps: any
    highlightedIndex: number | null
    index: number
    listRef: React.MutableRefObject<(HTMLElement | null)[]>
  }

export const SelectAll: React.FC<React.PropsWithChildren<SelectAllProps>> = ({
  testId = 'select-all',
  children,
  checkStatus,
  itemProps,
  highlightedIndex,
  listRef,
  index
}) => {
  loadStrings()
  const CheckIcon = React.useMemo(() => {
    if (checkStatus === 'partial') {
      return CheckboxIndeterminateIcon
    }
    if (checkStatus === 'unchecked') {
      return CheckboxUnselectedIcon
    }
    return CheckboxSelectedIcon
  }, [checkStatus])
  return (
    <li
      {...itemProps}
      data-testid={`${testId}-select-all`}
      className={cx(
        'px-3 type-default font-normal flex cursor-pointer -mb-px',
        'focus:outline-none',
        {
          'bg-darken-4': highlightedIndex === index
        }
      )}
      ref={(node: any) => {
        listRef.current[index] = node
      }}
    >
      <div
        className={cx(
          'flex flex-row items-top space-x-1 border-b w-full py-0.5 md:py-0'
        )}
      >
        <div className='px-2 py-2.5 leading-none'>
          <CheckIcon
            className={cx(
              checkStatus === 'unchecked' ? 'text-gray-100' : 'text-primary-75'
            )}
            aria-label={`(${
              checkStatus === 'unchecked' ? t('not-selected') : t('selected')
            })`}
          />
        </div>
        <div className='py-2.5'>{children}</div>
      </div>
    </li>
  )
}
