import * as React from 'react'
import { FieldConfig, useField } from 'formik'
import {
  SelectItem,
  SelectOption,
  Select,
  SelectProps
} from '@toasttab/buffet-pui-select'
import { RemoveFields } from '@toasttab/buffet-shared-types'
import { FormValuesWithName, TypedName } from '../commonTypes'

export type SelectFieldProps<
  FormValues extends FormValuesWithName = string,
  TValue = string,
  TItem extends SelectItem<any> = SelectOption<TValue>
> = RemoveFields<FieldConfig<TValue>, 'value' | 'children' | 'name'> &
  RemoveFields<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    'onChange' | 'value' | 'size' | 'name'
  > &
  RemoveFields<SelectProps<TValue, TItem>, 'onChange' | 'value' | 'name'> &
  TypedName<FormValues> & {
    // value is required by Select and Formik provides it at run time
    // at compile time value is not required for SelectField
    value?: TValue
    onChange?: (value: TValue) => void
  }

export const SelectField = <
  FormValues extends FormValuesWithName = string,
  TValue extends any = string,
  TItem extends SelectItem<any> = SelectOption<TValue>
>(
  props: SelectFieldProps<FormValues, TValue, TItem>
) => {
  const { onChange: propsOnChange, ...rest } = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ onChange, ...fields }, meta, helpers] = useField<TValue>({
    ...rest,
    value: props.value as any,
    size: undefined
  })
  const errorText = meta.error
  const invalid = meta.touched && !!errorText
  return (
    <Select
      invalid={invalid}
      errorText={errorText}
      onChange={(value) => {
        helpers.setTouched(true)
        helpers.setValue(value)
        propsOnChange?.(value)
      }}
      {...fields}
      {...rest}
    />
  )
}
